/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

$bodyfont:'Rubik', sans-serif;
$headingfont:'Rubik', sans-serif;


/*Theme Colors*/

$topbar: #fff;
$sidebar: #fff;
$sidebar-white: #ffffff;
$sidebar-alt:#e8eff0;
$bodycolor: #fff;
$headingtext: #2c2b2e;
$bodytext: #54667a;
$sidebar-text: #54667a;
$sidebar-icons: #a6b7bf;

$light-text: #a6b7bf;
$themecolor: #009efb;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #f62d51;
$success: #55ce63;
$warning: #ffbc34;
$primary: #7460ee;
$info: #009efb;
$inverse: #2f3d4a;
$muted: #90a4ae;
$dark: #263238;
$light: #f2f7f8;
$light2: #c1c1c1;
$extra-light: #ebf3f5;
$secondary: #868e96;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;



$danger-dark: #e6294b;
$success-dark: #4ab657;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);


/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #01c0c8;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
/*Preloader*/
.preloader{
    width: 100%;
    height: 100%;
    top:0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel{
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}
