body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*.swal-button {
  background-color: #6dc272;
}

.swal-button:not([disabled]):hover {
    background-color: #46a64b;
}*/

/*Go js*/
.diagram-component {
  width: 100%;
  height: 600px;
  border: solid 1px black;
  background-color: white;
}

.overView-component {
  border: 1px solid green;
  position: absolute;
  width:180px;
  height: 13%;
  background: #F0F8FF;
  z-index: 10;
  margin-top: 0px;
}

/*CSS table SVI liste files*/
.table-sviListFiles {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 90%;
}

.table-sviListFiles td, .table-sviListFiles th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-sviListFiles tr:nth-child(even){background-color: #f2f2f2;}

.table-sviListFiles tr:hover {background-color: #ddd;}

.table-sviListFiles th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #c9d2d3;
  color: white;
}

.table-sviListFiles label {
  margin: 0 2px;
}

.table-sviListFiles label span{
  font-size: small;
}

.modalPopup {
  font-size: 12px;
}
.modalPopup > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modalPopup > .content {
  width: 100%;
  padding: 10px 5px;
}
.modalPopup > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modalPopup > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.menuConfigSvi{cursor:pointer}
.titleConfigSvi{
  box-sizing:border-box;
  background:#fff;
  padding:14px;
  border-radius:4px;
  position:relative;
}
.dropdownConfigSvi{
  background:#fff;
  border-radius:4px;
  box-shadow:0 0 40px -10px #000;
  color:#505050;
  margin-top:24px;
  max-height:0;
  overflow:visible;
  transition:all .5s
}
.btConfigSvi{width: 90%;padding:8px;margin:2px;transition:all .1s;border: 1px solid coral;}
.btConfigSvi:hover{background:coral;-webkit-transform:scale(1.05);color:rgba(255,255,255,0.8);box-shadow:0 0 30px -10px #000}

hr.authLine {
  width: 100%;
  border: none;
  border-top: 1px dotted #8c8b8b;
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
  margin: 30px 0;
}

hr.authLine:after {
  background: #fff;
  content: 'ou';
  padding: 0 10px;
  position: relative;
  top: -13px;
}

.loader{color:#fff;position:fixed;box-sizing:border-box;left:-9999px;top:-9999px;width:0;height:0;overflow:hidden;z-index:999999}
.loader:after,.loader:before{box-sizing:border-box;display:none}
.loader.is-active{background-color:rgba(0,0,0,.85);width:100%;height:100%;left:0;top:0}
.loader.is-active:after,.loader.is-active:before{display:block}@keyframes rotation{0%{transform:rotate(0)}to{transform:rotate(359deg)}}@keyframes blink{0%{opacity:.5}to{opacity:1}}
.loader[data-text]:before{position:fixed;left:0;top:50%;color:currentColor;font-family:Helvetica,Arial,sans-serif;text-align:center;width:100%;font-size:14px}.loader[data-text=""]:before{content:"Loading"}.loader[data-text]:not([data-text=""]):before{content:attr(data-text)}.loader[data-text][data-blink]:before{animation:blink 1s linear infinite alternate}
.loader-default[data-text]:before{top:calc(50% - 63px)}
.loader-default:after{content:"";position:fixed;width:48px;height:48px;border:8px solid #fff;border-left-color:transparent;border-radius:50%;top:calc(50% - 24px);left:calc(50% - 24px);animation:rotation 1s linear infinite}.loader-default[data-half]:after{border-right-color:transparent}
.loader-default[data-inverse]:after{animation-direction:reverse}


.notfoundIcone {
  margin: 20px;
  width: 80px;
  height: 80px;
  background-image: url('/images/emoji.png');
  background-size: cover;
}
.circleIcone {
  width: 120px;
  height: 120px;
  background: #f2f5f8; 
  -moz-border-radius: 70px; 
  -webkit-border-radius: 70px; 
  border-radius: 70px;
}
