/**************************************
                ALL
*************************************/

.darkMode,
.darkMode div,
.darkMode td,
.darkMode th,
.darkMode .left-sidebar,
.darkMode .sidebar-nav,
.darkMode .sidebar-nav ul li a,
.darkMode .sidebar-nav ul li button,
.darkMode .card,
.darkMode .card-no-border .page-wrapper,
.darkMode .bg-white,
.darkMode .bg-light,
.darkMode .navbar-light .navbar-nav .nav-link,
.darkMode .topbar .top-navbar .navbar-header,
.darkMode .navbar-light .navbar-brand,
.darkMode .nav-tabs .nav-item.show .nav-link,
.darkMode .nav-tabs .nav-link.active,
.darkMode .custom-select,
.darkMode .dropdown-menu,
.darkMode .dropdown-item,
.darkMode .list-group-item,
.darkMode #sidebar-profile-btn {
  background-color: rgb(24, 26, 27) !important;
  background: rgb(24, 26, 27) !important;
  color: rgb(216, 213, 207) !important;
}

.darkMode .form-control {
  background: black !important;
  color: white !important;
}

.sidebar-nav > ul > li > button {
  border-left: 3px solid rgb(255,255,255);
}

.darkMode .sidebar-nav > ul > li > a {
	border-left: 3px solid rgb(24, 26, 27);
}

.darkMode .nav-tabs {
	border-bottom-color: rgb(52, 60, 69) !important;
}

.darkMode .MuiTypography-caption-94,
.darkMode .MuiIconButton-root-157,
.darkMode .MTablePaginationInner-root-148
.darkMode .MuiIconButton-root-157.MuiIconButton-disabled-161 {
	color: rgba(232, 230, 227, 0.54) !important;
}

.darkMode td {
	border-bottom-color: rgb(57, 57, 57) !important;
}

.darkMode h1,
.darkMode h1,
.darkMode h2,
.darkMode h3,
.darkMode h4,
.darkMode h5,
.darkMode h6,
.darkMode {
	color: rgb(216, 213, 207) !important;
}

.darkMode .table td,
.darkMode .table th {
	border-color: rgb(60, 51, 51);
}

.custom-select {
	color: rgb(202, 198, 190);
	border-color: rgb(54, 63, 72);
}

.darkMode .darkModeLight {
  background-color: rgb(42, 44, 46) !important;
}

button:focus {outline:0 !important;}

.page-wrapper {
  padding-bottom: 0px;
  background: white !important;
}

.bg-success-light {
  background: rgb(204, 255, 204);
}

.noButtonAppearence {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  background: transparent;
  border: none;
  cursor: pointer;
}

.card {
  border: none;
}

.form-control {
  color: #000000 !important;
}

.custom-select {
  color: #000;
}

button {
  border: none;
}

.material-switch > input[type="checkbox"] {
    display: none;
}

.material-switch > label {
    cursor: pointer;
    height: 0px;
    position: relative;
    width: 40px;
}

.material-switch > label::before {
    background: rgb(0, 0, 0);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    margin-top: -8px;
    position:absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}
.material-switch > label::after {
    background: rgb(255, 255, 255);
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
}

.modal-lg {
	max-width: 1000px;
}

.modal {
	overflow-y: auto;
}

.underlinedOnHover:hover {
  text-decoration: underline;
}

/**************************************
              GRIDSTACK
*************************************/

.react-draggable {
  cursor: move;
  border: 2px dotted #2196F3;
}

.fillCard {
  height: 100%;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
}

.react-grid-layout {
  position: relative;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}


/**************************************
              PRELOADER
*************************************/

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #78BE20;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

.preloader {
  position: relative;
  z-index: 0;
  height: 100px;
}

/**************************************
            INSTANT MESSAGING
*************************************/

#imIframe {
	width: 100%;
	height: 89vh;
  border: none;
}

/**************************************
            MODAL > SEARCH
*************************************/

#searchInput {
  font-size: 2rem;
}

#searchInput::placeholder {
  font-size: 2rem;
  color: #DDDDDD;
}

.form-material .form-control, .form-material .form-control.focus, .form-material .form-control:focus {
    background-image: -webkit-linear-gradient(#28a745, #55ce63), -webkit-linear-gradient(#d9d9d9, #d9d9d9);
    background-image: linear-gradient(#28a745, #55ce63), linear-gradient(#d9d9d9, #d9d9d9);
}

#searchResultsTable td.profileImage {
  width: 10%;
}

#searchResultsTable td.actions {
  text-align: right;
}

#searchResultsTable td.actions button {
  margin-left: 5px;
}

/**************************************
              RIGHT CLICK
*************************************/

.react-contextmenu {
  background: #FFFFFF;
  border: 1px solid #28a745;
  padding: 10px 5px;
  z-index: 9999;
}

.react-contextmenu-item {
  padding: 5px 10px;
  cursor: pointer;
}

.react-contextmenu-item:hover {
  background: #DDDDDD;
}

/**************************************
            BREADCRUMB
*************************************/

.breadcrumb-item button {
  background: transparent;
  border: none;
  font-weight: bold;
  color: #009efb;
  padding: 0;
  cursor: pointer;
}

/**************************************
              FORGOT
*************************************/

/**************************************
              KONAMI
*************************************/

@font-face {
  font-family: 'Konsystem';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Konsystem.ttf") format("truetype"),
}

.slide-in-bck-center-square {
  font-family: 'Konsystem';
	-webkit-animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bck-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.konamiFont {
  font-family: "Konsystem";
  font-size: 4rem;
  text-align: center;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-7 10:38:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.login-register {
  background-image:url(../images/background/KERTEL-ONE-FINAL3.png);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/**************************************
              TOPBAR
*************************************/
#mainNavbar {
  background: rgb(81, 205, 95);
  height: 20px;
}

.adminNavBar {
  background: #990000 !important;
}

.adminNavBar::before {
  content: 'Vous agissez en tant qu\'admin';
  color: #FFFFFF;
  font-weight: bold;
  position: relative;
  top: 1px;
  left: 10px;
}

.topbar .top-navbar {
	min-height: 20px;
	padding: 0px 15px 0 0;
}

.sidebartoggler {
  -webkit-appearance: button-bevel;
  -moz-appearance:    button-bevel;
  appearance:         button-bevel;
  background: transparent;
  color: #FFFFFF !important;
}

.nav-item button {
  -webkit-appearance: button-bevel;
  background: transparent;
  // color: #FFFFFF !important;
}

.mailbox ul li button.nav-link {
  color: #888888 !important;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

navbar-expand-lg .navbar-nav .dropdown-menu {
  margin-top: -5px;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin-top: -5px;
  }
}


/**************************************
        TOPBAR > NOTIFICATIONS
*************************************/
div#actions {
  display: flex;
  float: right;
  box-sizing: content-box;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

div#actions > div {
  margin-left: 5px;
  float: right;
}

.mailbox .message-center div.topbarNotifContainer {
	border-bottom: 1px solid rgba(120, 130, 140, 0.13);
	display: block;
	text-decoration: none;
	padding: 9px 15px;
}

.mailbox .message-center div.topbarNotifContainer .mail-contnet {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
}

.mailbox .message-center div.topbarNotifContainer .mail-contnet h5 {
  margin: 5px 0px 0;
}

.mailbox .message-center div.topbarNotifContainer .mail-contnet .mail-desc, .mailbox .message-center div.topbarNotifContainer .mail-contnet .time {
  font-size: 12px;
  display: block;
  margin: 1px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color:
  #54667a;
  white-space: nowrap;
}

/**************************************
              SIDEBAR
*************************************/

#sidebar-profile-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background: none;
  border: none;
  color: #545454;
}

.sidebar-nav .has-arrow::after {
	-webkit-transform: rotate(135deg) translate(0, -50%);
	transform: rotate(135deg) translate(0, -50%);
}

.sidebar-nav .active > .has-arrow::after, .sidebar-nav li > .has-arrow.active::after, .sidebar-nav .has-arrow[aria-expanded="true"]::after {
	-webkit-transform: rotate(225deg) translate(0, -50%);
	transform: rotate(225deg) translate(0, -50%);
}

.topbar {
  width: 101%;
}

.topbar .top-navbar .navbar-header {
    background: #FFFFFF;
}

.saveEditDashboard {
  background: #03A9F4 !important;
  line-height: 2 !important;
  border: 2px solid #2196F3 !important;
  -webkit-appearance: button !important;
}

.sidebar-nav ul li a, .sidebar-nav ul li button {
  text-align: left;
}

.sidebar-nav ul li a, .sidebar-nav ul li button.selected {
  color: #009efb;
}

.sidebar-nav .material-switch.pull-right {
	position: relative;
	left: 25px;
	top: 3px;
}

#sidebarIcons {
	margin: 15px auto;
	padding: 0;
  text-align: center;
  width: 100%;
}

#sidebarIcons .col-lg-3 {
	margin: auto;
	padding: 0;
	text-align: center;
}

#sidebarIcons .col-lg-3 button {
	text-align: center;
	margin: auto;
  padding: 0;
  cursor: pointer;
}

#sidebarIcons .col-lg-3 button i {
  color: #51cd5f;
  font-size: 1.2rem;
}

/**************************************
        DASHBOARD > INBOX
*************************************/

.callsLogsDashboard {
  overflow-y: auto;
}

/**************************************
        DASHBOARD > FREQUENTS
*************************************/

#dashBoardFrequents {
	height: 522px;
}

#tRexRunner {
  width: 100%;
  border: none;
  min-height: 240px;
}

#dashBoardFrequents .frequentItem .underlinedOnHover:hover {
  cursor: pointer;
}

/**************************************
        DASHBOARD > IM
*************************************/

#dashBoardIMFrequents {
	min-height: 436px;
}

#dashBoardFrequents h5 {
  text-transform: lowercase;
  text-overflow: ellipsis;
  height: 15px;
  white-space: nowrap;
}
#dashBoardFrequents h5:first-letter {
  text-transform: uppercase;
}

/**************************************
              DASHBOARD
*************************************/

.Dashboard {
  background: rgb(193, 193, 193);
  padding: 15px 17px;
}

/**************************************
              SCHEDULER
*************************************/

.schedulerSubTable:hover {
  background: none !important;
}

.createDayColorItem {
  padding: 20px;
  width: 50px;
  display: inline;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.rc-calendar-tbody .droptarget {
  height: 100%;
}

.dayColorRenderContainer { position: relative; overflow: hidden; padding: 0; }

.dayColorRender:before{
  content: '';
  position: absolute;
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.4) 50%);
  background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.4) 50%);
  background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.4) 50%);
  background-size: 100% 20px;
  top: 0px;
  left: 0px;
  height: 150%;
  width: 150%;
  -webkit-transform-origin: 15% 60%;
  -moz-transform-origin: 15% 60%;
  transform-origin: 15% 60%;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/**************************************
              USER SUPERVISION
*************************************/


.md-chip {
  display: inline-block;
  background: #e0e0e0;
  padding: 0 12px;
  border-radius: 32px;
  font-size: 13px;
  margin: 5px;
}
.md-chip.md-chip-hover:hover {
  background: #ccc;
  cursor: pointer;
}

.md-chip-clickable {
  cursor: pointer;
}

.md-chip, .md-chip-icon {
  height: 32px;
  line-height: 32px;
}

.md-chip-icon {
  display: block;
  float: left;
  background: #CCCCCC;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin: 0 8px 0 -12px;
}

.md-chip-icon-sIdle  {
  background: #CCCCCC;
}

.md-chip-icon-sRingback   {
  background: #06da46;
}

.md-chip-remove {
  display: inline-block;
  background: #aaa;
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0 -4px 0 4px;
  cursor: pointer;
  font: inherit;
  line-height: 20px;
}
.md-chip-remove:after {
  color: #e0e0e0;
  content: "x";
}
.md-chip-remove:hover {
  background: #999;
}
.md-chip-remove:active {
  background: #777;
}

.md-chips {
  padding: 12px 0;
}
.md-chips .md-chip {
  margin: 0 5px 3px 0;
}

.md-chip-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.pulse i, .pulseOff i {
  color: #fff;
  font-size: 1rem;
}

.pulse, .pulseOff {
  height: 100%;
  width: 100%;
  background-color: rgb(150, 150, 150);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative
}

.pulseRinging, .pulsePaused, .pulseBusy {
  background-color: orange;
}

.pulseConnected {
  background-color: #00ac00;
}

.pulseClosed {
  background-color: #ac0009;
}

.pulse::before, .pulsePaused::before, .pulseBusy::before {
  content: "";
  position: absolute;
  border: 1px solid orange;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  animation: pulse 1s linear infinite
}

.pulse::after, .pulsePaused::after, .pulseBusy::after {
  content: "";
  position: absolute;
  border: 1px solid ORANGE;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s
}

.pulseConnected::before {
  border: 1px solid #00ac00;
}

.pulseConnected::after {
  border: 1px solid #00ac00;
}

@keyframes pulse {
  0% {
      transform: scale(0.5);
      opacity: 0
  }

  50% {
      transform: scale(1);
      opacity: 1
  }

  100% {
      transform: scale(1.3);
      opacity: 0
  }
}

/**************************************
              CALENDAR
*************************************/

.rc-calendar-fullscreen .rc-calendar-cell {
	width: 155px !important;
}

.calendarCell {
  width: 40px;
  float: right;
  clear: both;
}

.droptarget p {
  clear: both;
  display: block;
  box-sizing: content-box;
  padding: 3px 10px;
  border-radius: 4px;
  max-width: 155px;
}

.todayCell {
  background: #51CD5F;
  padding: 4px;
  color: #FFFFFF;
  border-radius: 20px;
}

.calendarDayClose {
	color: #FF0000;
	text-shadow: none;
	background: #FFFFFF !important;
	opacity: 1;
	padding: 0 5px !important;
	border-radius: 10px;
  font-size: 15px !important;
}

.rc-calendar-full-header-switcher {
  display: none;
}

/**************************************
              MUSICS
*************************************/

.musicActionsButtons {
  text-align: right;
}

/**************************************
              CONTACTS
*************************************/

.MuiPaper-elevation2-200, .MuiPaper-elevation2-9 {
  box-shadow: none !important;
}

.MuiTableSortLabel-root-1125 {
  font-weight: bold;
}

div#gi table tr td,
div#users table tr td,
div#list table tr td,
div#calls table tr td,
div#incomings table tr td,
div#outgoings table tr td,
div#voiceMails table tr td,
div#incomingFax table tr td,
div#outgoingFax table tr td
{
  //font-size: small !important;
  color: #444;
  padding: 8px;
  border: none;
  border-top: 1px solid #ececec !important;
}

div#gi table tr:nth-of-type(odd),
div#users table tr:nth-of-type(odd),
div#list table tr:nth-of-type(odd),
div#calls table tr:nth-of-type(odd),
div#incomings table tr:nth-of-type(odd),
div#outgoings table tr:nth-of-type(odd),
div#voiceMails table tr:nth-of-type(odd),
div#incomingFax table tr:nth-of-type(odd),
div#outgoingFax table tr:nth-of-type(odd)
{
  background: #f2f7f8;
  border: none;
}

/**************************************
              DASHBOARD > DIRECT
*************************************/

#directKeys {
  overflow-y: auto;
  overflow-x: hidden;
}

#directKeys .card {
  margin-bottom: 10px;
  display: block;
}

#directKeys .card-body {
  column-count: 2;
}

#directKeys .col-xlg-6 .card {
  margin-bottom: 0;
}

#directKeys .btn-circle {
	width: 40px;
	height: 40px;
	padding: 6px;
	font-weight: bold;
	color: #000000;
	font-size: 20px;
}

#directKeys .col-md-4.col-lg-3.text-left {
  padding-right: 0;
  max-width: 70px;
}

#directKeys .col-md-8.col-lg-9 {
  padding-left: 0;
}

#directKeys i {
	position: relative;
	top: 6px;
	font-size: 20px;
}

/**************************************
              DASHBOARD > PHONE
*************************************/

#phonedial .col-lg-4 {
    padding: 0;
    border: 1px solid #FFFFFF;
    display: inline-block;
}

#phonedial .col-lg-4 button {
  font-size: 0.7rem;
  line-height: 1;
  color: #FFFFFF;
  height: 58px;
  background: #7b7b7b;
}

#phonedial #phonedialTwiceOptions {
  margin: auto;
  margin-bottom: 10px;
}

/* #phonedial .col-lg-4 button:not(.btn-success) {
    background: #868686;
} */

#phonedial .col-lg-4 button span.dialNumber {
  font-size: 1.4rem;
}

#phonedial .col-lg-4 button:not(.btn-success):hover {
    background: #a5a5a5;
}

.react-tel-input input[type=tel], .react-tel-input input[type=text] {
    width: 100% !important;
}

/**************************************
              CALL TOAST
*************************************/

.incomingToast {
  padding: 0;
  width: 450px;
  position: absolute;
  right: 0;
}

#incomingCall {
	position: fixed;
	top: 10px;
	right: -100%;
	box-shadow: 0px 0px 5px #d0d0d0;
	width: 300px;
  z-index: 100;
  animation-name: incomingCall;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#incomingCall .card-header {
	background: #51cd5f;
  border: none;
}

#incomingCall .card-body {
	padding: 5px;
}

#incomingCall .media {
	margin-bottom: 10px;
	padding: 15px;
  border: none;
}

@keyframes incomingCall {
  from {
    right: -100%;
  }

  to {
    right: 10px;
  }
}

/**************************************
              MUSIC
*************************************/
table#musicTable {
  position: relative;
  top: 0px;
  margin-bottom: 0;
}

// #musicCard .card-body {
//   padding-top: 8px;
// }

// #musicTable tr {
//   height: 35px;
// }

// #musicTable td {
//   padding: 0 15px;
// }

#musicTable .btn-circle {
  // width: 30px;
  // height: 30px;
  // padding: 0px 7px;
  margin-left: 5px;
}

#musicTableActions {
  margin-bottom: 0;
  margin-top: 10px;
}

#musicTableActions .col-3 {
  position: relative;
  right: -15px;
}

/**************************************
              FORWARD
*************************************/

#forwardDashboardForm {
  font-size: 0.9rem;
}

.forwardBorderBottom {
  padding-bottom: 10px;
  border-bottom: 1px solid #DDD;
  margin-bottom: 10px;
}

/**************************************
              CALL
*************************************/

.activeCall {
  background-color:  #51cd5f;
  animation-name: color;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  padding: 10px;
  border-radius: 4px;
  color: #FFFFFF;
  width: 100%;
}

.activeCallHold {
  background-color:  #dd7d00;
  animation-name: colorHold;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

/*.activeCall .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}*/

.activeCall .activeCallIcons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.activeCall .callActionIcons .hangup {
background-color: red;
color: white;
padding: 5px 10px;
border-radius: 25px;
cursor: pointer;
}

.row.activeCallDTMF {
	width: 100%;
	margin: 25px auto 0 auto;
}

.activeCallDTMF .col-lg-4 {
	padding: 2px;
}

.callActionIcons {
  margin-top: 10px;
}

.activeCall .callActionIcons .hangup:hover {
  background-color: rgb(255, 79, 79);
  }

  .callActionIcons i:hover {
    cursor: pointer;
    color: rgb(0, 0, 0);
  }

  .callActionIcons i.mutedIcon {
    color: rgb(255, 0, 0);
  }

@keyframes color {
  0% {
    background-color: #51cd5f;
  }
  50% {
    background-color: #348a3e;
  }
  100% {
    background-color: #51cd5f;
  }
}

@keyframes colorHold {
  0% {
    background-color: #dd7d00;
  }
  50% {
    background-color: #ff9100;
  }
  100% {
    background-color: #dd7d00;
  }
}

.Call {
  height: 92.7vh;
}

div#callContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#callContainer img {
  width: 200px;
}

div#callContainer > div.row:first-of-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#callContainer .round {
    width: 20px;
    height: 20px;
    margin: 7px;
}

.btn_call {
  padding-left: 5px;
  padding-right: 5px;
}

.hangUp {
  transform: rotate(137deg);
  position: relative;
  top: -2px;
  left: -1px;
}


.spinner {
  width: 100%;
  text-align: center;
}

.spinner > div {
  width: 25px;
  height: 25px;
  background-color: #28a745;
  margin-right: 5px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce2 {
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

.spinner .bounce3 {
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
.spinner .bounce4 {
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s;
}
.spinner .bounce5 {
  -webkit-animation-delay: 0.64s;
  animation-delay: 0.64s;
}
.spinner .bounce6 {
  -webkit-animation-delay: 0.80s;
  animation-delay: 0.80s;
}
.spinner .bounce7 {
  -webkit-animation-delay: 0.96s;
  animation-delay: 0.96s;
}


@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.5) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  } 40% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

/**************************************
              FAX
*************************************/

.animated {
  animation-duration: .8192s;
  animation-fill-mode: backwards;
  transform-style: preserve-3d;
}

/** intro */
@keyframes intro {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(0, 0, -50px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.intro {
  animation: intro 1s ease-out;
}

/** enterRight */
@keyframes enterRight {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterRight {
  animation-name: enterRight;
}

/** enterLeft */
@keyframes enterLeft {
  from {
    opacity: 0;
    transform: perspective(500px) translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.enterLeft {
  animation-name: enterLeft;
}

/** exitRight */
@keyframes exitRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(100%, 0, -100px);
  }
}

.exitRight {
  animation-name: exitRight;
}

/** exitLeft */
@keyframes exitLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: perspective(500px) translate3d(-100%, 0, -100px);
  }
}

.exitLeft {
  animation-name: exitLeft;
}

/**************************************
              DROPZONE
*************************************/

.dropzone {
  width: 100%;
  border: 3px dashed #9ee1ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background: aliceblue;
}

.wrongFormat {
  background: #ffc9c9;
  border-color: #ff6767;
}

.rightFormat {
  background: #e5fbe8;
  border-color: #47bb5c;
}

/**************************************
              HOME
*************************************/

#loginContainer {
  width: calc(100% + 240px);
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: row;
  left: -240px;
}

#titlesContainer {
  margin-left: -170px;
}

#leftHomeContainer {
  width: 75%;
  height: 100vh;
  background: url(../images/background/KERTEL-ONE-FINAL3.png) no-repeat center center;
  background-size: contain;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#firstTitle h1 {
  color: #65B32E;
  font-size: 4rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

div#firstTitle h1 span {
  color: #61676C;
}

#secondTitle h2 {
  color: #61676C;
  font-size: 1.3rem;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

#slogan {
  position: absolute;
  bottom: 140px;
}

#slogan h3 {
  color: #FF6900;
}

#rightHomeContainer {
	width: 25%;
	height: 100vh;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

div#homeBrand {
  padding: 20px;
  box-sizing: border-box;
}

div#homeBrand img {
  display: block;
  margin: auto;
  width: 70%;
}

div#homeFormContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 70%;
  margin-top: 50px;
}

div#homeFormContainer form {
  width: 100%;
}

div#homeFormContainer input {
  border-top: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-left: none;
  border-radius: 0;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

div#homeFormContainer button {
  width: 100%;
  margin: 5px 0;
}

div#homeFormContainer .custom-checkbox {
  text-align: left;
}

#homeFooter {
	position: absolute;
	bottom: 20px;
	color: #999;
}

header#user_header.is_stuck {
	top: 0 !important;
}
